import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

interface IndexPageProps {
  data: any
  pageContext: {
    locale: string
    name: string
  }
}

const IndexPage: React.FC<IndexPageProps> = ({
  data: { prismicHeader, prismicFooter, prismicHomepage },
  pageContext,
}) => {
  return (
    <Layout
      pageContext={pageContext}
      headerData={prismicHeader.data}
      footerData={prismicFooter.data}
    >
      <SEO title="Home" />
      <h1>{prismicHomepage.data.title.text}</h1>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery($locale: String!) {
    prismicHeader(lang: { eq: $locale }) {
      ...HeaderQuery
    }

    prismicFooter(lang: { eq: $locale }) {
      ...FooterQuery
    }

    prismicHomepage(lang: { eq: $locale }) {
      data {
        title {
          text
        }
      }
    }
  }
`
